import React from 'react';

class Footer extends React.Component {
    componentDidMount() {
        var aScript = document.createElement('script');
        aScript.type = 'text/javascript';
        aScript.src = "js/main.js";
        document.head.appendChild(aScript);
    }
    render() {
        const year = new Date().getFullYear();
        return (
            <footer>
                <div className="row footer-main">
                    <div className="col-six tab-full left footer-desc">
                        <div className="footer-logo"></div>
                        <dl>
                            <dt><strong>Our Mission</strong></dt>
                            <dd>
                                is to provide our clients with flexible business services that help them to become a leader in in their industry.
                            </dd>
                        </dl>
                    </div>

                    <div className="col-six tab-full right footer-subscribe">
                        <h4>Get Notified</h4>
                        <p>When you join our newsletter, we will send you the latest information about our company and any unique opportunities, events or real investment deals.  Join now and don't let these great opportunities get away!</p>

                        <div className="subscribe-form">
                            <form id="mc-form" className="group" noValidate>
                                <input type="email" name="EMAIL" className="email" id="mc-email" placeholder="Email Address" required />
                                <input type="submit" name="subscribe" value="Subscribe" />
                                <label htmlFor="mc-email" className="subscribe-message"></label>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="row footer-bottom">
                    <div className="col-twelve">
                        <div className="copyright">
                            <span>© Copyright Business Sparkle LLC {year}</span> 
                        </div>

                        <div className="go-top">
                            <a className="smoothscroll" title="Back to Top" // eslint-disable-line 
                            href="#top"><i className="icon-arrow-up" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;