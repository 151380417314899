import React from 'react';

class Services extends React.Component {
    render() {
        return(
            <section id='services' className="s-services">

                <div className="row section-header has-bottom-sep" data-aos="fade-up">
                    <div className="col-full">
                        <h3 className="subhead">What We Do</h3>
                        <h1 className="display-2">Global Business Networking &#183; Global Investment &#183; IT Consulting &#183; Trading</h1>
                    </div>
                </div>

                <div className="row services-list block-1-2 block-tab-full">
                    <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="icon-lego-block"></i>
                        </div>
                        <div className="service-text">
                            <h3 className="h2">Information Technology & Web Consulting Services</h3>
                            <p>Nemo cupiditate ab quibusdam quaerat impedit magni. Earum suscipit ipsum laudantium. 
                            Quo delectus est. Maiores voluptas ab sit natus veritatis ut. Debitis nulla cumque veritatis.
                            Sunt suscipit voluptas ipsa in tempora esse soluta sint.
                            </p>
                        </div>
                    </div>

                    <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="icon-earth"></i>
                        </div>
                        <div className="service-text">
                            <h3 className="h2">Setup Business Offshore Entities</h3>
                            <p>We have many years of experience with assisting our customer with setting up buisnesses in the United States.</p>
                            <p>Based on the type of entity you'd like to setup, it's best to <a className="smoothscroll"  href="#contact" title="contact">contact us</a> to provide more information about your business and what your goals are in setting up an entity here in the United States.</p>
                        </div>
                    </div>

                    <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="icon-megaphone"></i>
                        </div>  
                        <div className="service-text">
                            <h3 className="h2">VIP Special Events</h3>
                            <p>One of our most sought after services. We have events that are not sold to the public and are by invitation only. These VIP private special events are exclusive and seats are always limited and goes quickly.</p>
                            <p>Due to different events having specific requirements from the attendees, we are not allowed to display this online and must talk to the person directly.</p>
                            <p><a className="smoothscroll"  href="#contact" title="contact">contact us</a> now to get more detail information.</p>
                        </div>
                    </div>

                    <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="icon-group"></i>
                        </div>
                        <div className="service-text">
                            <h3 className="h2">Business Development & Outsourcing Strategies</h3>
                            <p>In today’s competition business environment, a strong strategic understanding must be the goal of every organization. Organizations that remain competitive over a long period have vision that adapts to the changing world of today.</p>
                            <p>Business Sparkle strategic process and successful global networking can help organizations achieve their visions. Our insightful understanding of current and future market needs combined with our global market knowledge will be our key to assist our clients build proper tasks and goals. There are customers with needs to develop stronger business networks to either help them increase the number or suppliers, optimize their costs, and/or increase their business markets. Our connections with many global companies enable us to assist our customers in these matters.</p>
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}

export default Services;