import React from 'react';

class Header extends React.Component {
    render() {
        return(
            <header className="s-header">
                <div className="header-logo">
                    <a className="site-logo" href="/">
                        <img src="images/BusinessSparkleLogo.png" alt="Homepage" />
                    </a>
                </div>

                <nav className="header-nav">

                    <a href="/#0" className="header-nav__close" title="close"><span>Close</span></a>

                    <div className="header-nav__content">
                        <h3>Navigation</h3>
                        
                        <ul className="header-nav__list">
                            <li className="current"><a className="smoothscroll"  href="#home" title="home">Home</a></li>
                            <li><a className="smoothscroll"  href="#about" title="about">About</a></li>
                            <li><a className="smoothscroll"  href="#services" title="services">Services</a></li>
                            {/* <li><a className="smoothscroll"  href="#works" title="works">Works</a></li>
                            <li><a className="smoothscroll"  href="#clients" title="clients">Clients</a></li> */}
                            <li><a className="smoothscroll"  href="#contact" title="contact">Contact</a></li>
                        </ul>
            
                        <p>Thank you for taking the time to understand our business.  Please feel free to <a className="smoothscroll"  href="#contact" title="contact">contact us</a> if you have any questions.</p>
            
                        {/* <ul className="header-nav__social">
                            <li>
                                <a // eslint-disable-line
                                href="#"><i className="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a // eslint-disable-line
                                href="#"><i className="fa fa-twitter"></i></a>
                            </li>
                            <li>
                                <a // eslint-disable-line
                                href="#"><i className="fa fa-instagram"></i></a>
                            </li>
                            <li>
                                <a // eslint-disable-line
                                href="#"><i className="fa fa-behance"></i></a>
                            </li>
                            <li>
                                <a // eslint-disable-line
                                href="#"><i className="fa fa-dribbble"></i></a>
                            </li>
                        </ul> */}
                    </div>
                </nav>

                <a className="header-menu-toggle" href="#0">
                    <span className="header-menu-text">Menu</span>
                    <span className="header-menu-icon"></span>
                </a>
            </header>
        )
    }
}

export default Header;