import React from 'react';

class Home extends React.Component {
    render() {
        return (
            // <!-- home
           // ================================================== -->
           <section id="home" className="s-home target-section" data-parallax="scroll" data-image-src="images/hero-bss.png" data-natural-width="3000" data-natural-height="2000" data-position-y="center">
               <div className="overlay"></div>
               <div className="shadow-overlay"></div>
       
               <div className="home-content">
               <div className="row home-content__main">
                   <h3>Welcome to Business Sparkle</h3>
                   <h1>
                       We specialist in global <br/>
                       networking, VIP private events <br/>
                       and custom software digital <br/>
                       experiences
                   </h1>
       
                   <div className="home-content__buttons">
                       <a href="#contact" className="smoothscroll btn btn--stroke">
                           Start a Project
                       </a>
                       <a href="#about" className="smoothscroll btn btn--stroke">
                           More About Us
                       </a>
                   </div>
               </div>
       
               <div className="home-content__scroll">
                   <a href="#about" className="scroll-link smoothscroll">
                       <span>Scroll Down</span>
                   </a>
               </div>
               <div className="home-content__line"></div>
               </div>
       
               {/* <ul className="home-social">
                   <li>
                       <a href="#0"><i className="fa fa-facebook" aria-hidden="true"></i><span>Facebook</span></a>
                   </li>
                   <li>
                       <a href="#0"><i className="fa fa-twitter" aria-hidden="true"></i><span>Twiiter</span></a>
                   </li>
                   <li>
                       <a href="#0"><i className="fa fa-instagram" aria-hidden="true"></i><span>Instagram</span></a>
                   </li>
                   <li>
                       <a href="#0"><i className="fa fa-behance" aria-hidden="true"></i><span>Behance</span></a>
                   </li>
                   <li>
                       <a href="#0"><i className="fa fa-dribbble" aria-hidden="true"></i><span>Dribbble</span></a>
                   </li>
               </ul>  */}
           </section>   
        )   
    }
}

export default Home;