import React from 'react';

class About extends React.Component {
    render() {
        return (
            <section id='about' className="s-about">

                <div className="row section-header has-bottom-sep" data-aos="fade-up">
                    <div className="col-full">
                        <h3 className="subhead subhead--dark">About Us</h3>
                        <h1 className="display-1 display-1--light">Our Company</h1>
                    </div>
                </div>

                <div className="row about-desc" data-aos="fade-up">
                    <div className="col-full">
                        <p>Business Sparkle LLC, is a global consulting firm which serves clients in North America, Europe, and Asia to assist them with the ever changing facets of the international markets. We established ourselves to help our clients solve the toughest problems with services in their business entities by providing strategies and methods, organizational changes, operations, marketing, and information technology.</p>

                        <p>One of our major success is in our customized Software development sector. Our in-depth knowledge of our customer's daily operational needs provided a solid platform for our IT team to architect and build software solutions for our clients to make their organization effective and compete with the technology intensive global market today.</p>

                        <p>Our mission is to provide our clients with flexible business services that help them to become a leader in in their industry.</p>

                        <p>Business Sparkle primary services include market feasibility studies, business plans, operational reviews, re-engineering (IT plan & e-Marketing plan), and strategic planning.</p>
                    </div>
                </div> 

                <div className="row about-stats stats block-1-2 block-m-1-2 block-mob-full" data-aos="fade-up">
                        
                    {/* <div className="col-block stats__col ">
                        <div className="stats__count">127</div>
                        <h5>Awards Received</h5>
                    </div> 
                    <div className="col-block stats__col">
                        <div className="stats__count">1505</div>
                        <h5>Cups of Coffee</h5>
                    </div> */}
                    <div className="col-block stats__col">
                        <div className="stats__count">109</div>
                        <h5>Projects Completed</h5>
                    </div>
                    <div className="col-block stats__col">
                        <div className="stats__count">102</div>
                        <h5>Happy Clients</h5> 
                    </div>

                </div> 

                <div className="about__line"></div>

            </section>
        )
    }
}

export default About;