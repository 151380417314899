import React from 'react';

class Contact extends React.Component {
    onSubmit = e => {
        e.preventDefault();
        // console.log('form submitted');
    };

    render() {
        return(
            <section id="contact" className="s-contact">

                <div className="overlay"></div>
                <div className="contact__line"></div>

                <div className="row section-header" data-aos="fade-up">
                    <div className="col-full">
                        <h3 className="subhead">Contact Us</h3>
                        <h1 className="display-2 display-2--light">Reach out to tell us about your project or any inquries</h1>
                    </div>
                </div>

                <div className="row contact-content" data-aos="fade-up">
                    
                    <div className="contact-primary">

                        <h3 className="h6">Send Us A Message</h3>

                        <form name="contactForm" id="contactForm" noValidate onSubmit={this.onSubmit}>
                            <fieldset>

                            <div className="form-field">
                                <input name="contactName" type="text" id="contactName" placeholder="Your Name" required aria-required="true" className="full-width" />
                            </div>
                            <div className="form-field">
                                <input name="contactEmail" type="email" id="contactEmail" placeholder="Your Email" required aria-required="true" className="full-width" />
                            </div>
                            <div className="form-field">
                                <input name="contactSubject" type="text" id="contactSubject" placeholder="Subject" required className="full-width" />
                            </div>
                            <div className="form-field">
                                <textarea name="contactMessage" id="contactMessage" placeholder="Your Message" rows="10" cols="50" required aria-required="true" className="full-width"></textarea>
                            </div>
                            <div className="form-field">
                                <button className="full-width btn--primary">Submit</button>
                                <div className="submit-loader">
                                    <div className="text-loader">Sending...</div>
                                    <div className="s-loader">
                                        <div className="bounce1"></div>
                                        <div className="bounce2"></div>
                                        <div className="bounce3"></div>
                                    </div>
                                </div>
                            </div>

                            </fieldset>
                        </form>

                        <div className="message-warning">
                            Something went wrong. Please try again.
                        </div> 
                    
                        <div className="message-success">
                            Your message was sent, thank you!<br/>
                        </div>

                    </div>

                    <div className="contact-secondary">
                        <div className="contact-info">

                            <h3 className="h6 hide-on-fullwidth">Contact Info</h3>

                            <div className="cinfo">
                                <h5>We Are Located At</h5>
                                <p>
                                    570 7th Avenue, Suite 2103<br/>
                                    New York, NY<br/>
                                    10018 US
                                </p>
                            </div>

                            <div className="cinfo">
                                <h5>Email Us At</h5>
                                <p>
                                    inquiry@businesssparkle.com<br/>
                                </p>
                            </div>

                            <div className="cinfo">
                                <h5>Call Us At</h5>
                                <p>
                                    Phone: +1.646.807.9696
                                </p>
                            </div>

                            {/* <ul className="contact-social">
                                <li>
                                    <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="fa fa-behance" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="fa fa-dribbble" aria-hidden="true"></i></a>
                                </li>
                            </ul> */}

                        </div>
                    </div>
                </div>

            </section>
        )
    }
}

export default Contact;