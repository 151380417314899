import React, { Fragment } from 'react';
import Home from './home';
import Header from './header';
import About from './about';
import Services from './services';
import Contact from './contact';
import Footer from './footer';

function App() {
  return (
    <Fragment>
      <Header></Header>
      <Home></Home>
      <About></About>
      <Services></Services>
      <Contact></Contact>
      <Footer></Footer>
    </Fragment>
  );
}

export default App;
